const site = {
  pathPrefix: '/1',
  title: '지나가던 개발(zigae)',
  author: 'geonwoo',
  description: '집보다 코드가 깨끗한 개발자',
  siteUrl: 'https://www.zigae.com/',
  twitter: 'zigae3',
  github: 'zi-gae',
  medium: 'zigae',
  // facebook: 'zigae',
  disqusShortName: 'gatsby-simple-blog',
  googleTrackingId: 'G-2PN51NC2VB',
  lang: 'ko',
  displayTranslations: true,
  postsPerPage: 30,
};

const supportedLanguages = {
  ko: 'Korean',
  en: 'English',
};

module.exports = {
  site,
  supportedLanguages,
};
